// Import necessary dependencies and styles
import React from "react";
import Card from "./Card";
import "./Modal.css";

// Defines a generalized modal used 
// throughout the application
function Modal(props) {
  // Concatenate the provided className with the base 'modal' class
  let modalCSS = "modal " + props.className;

  return (
    // Render a Card component to create the backdrop of the modal
    <Card className="backdrop">
      {/* Render a div element with the provided className to display the modal */}
      <div className={modalCSS}>
        {/* Render the content of the modal */}
        {props.children}
      </div>
    </Card>
  );
}

export default Modal;

