// Import necessary dependencies and styles
import React, { useContext } from "react";
import CartContext from "../../Context/cart-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import "./CartItem.css";

// Defines an individual cart item that includes 
// the meal name, price, and amount of that item.
function CartItem(props) {
  // Access the CartContext to get information about the cart items
  const cartContext = useContext(CartContext);

  // Find the corresponding cart item in the cartContext
  const cartItem = cartContext.cartItems.find(item => item.name === props.name);

  // Event handler to increment the quantity of the cart item
  const handleIncrement = () => {
    cartContext.onHandleIncDec(props.name, 1);
  };

  // Event handler to decrement the quantity of the cart item
  const handleDecrement = () => {
    // Ensure the quantity does not go below 0
    if (cartItem.amount >= 0) {
      cartContext.onHandleIncDec(props.name, -1);
    }
  };

  // Event handler to remove the cart item from the cart
  const trashClickHandler = () => {
    cartContext.onHandleIncDec(props.name, -cartItem.amount);
  };

  return (
    <li key={props.id} className="cart-item">
      <div>
        <h2>{props.name}</h2>
        <div className="cart-item-summary">
          <p className="price">${props.price}</p>
          <div className="amount">x {cartItem.amount}</div>
          <FontAwesomeIcon className='fa-trash' onClick={trashClickHandler} icon={faTrash} />
        </div>
      </div>
      <div className="cart-item-actions">
        {/* Buttons to increment and decrement the cart item quantity */}
        <button onClick={handleDecrement}>-</button>
        <button onClick={handleIncrement}>+</button>
      </div>
    </li>
  );
}

export default CartItem;

