// Import necessary dependencies and styles
import React, { useContext, useEffect, useState } from 'react';
import Button from '../UI/Button';
import CartContext from '../../Context/cart-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import './CartButton.css';

// Component that renders the cart button on the main page
function CartButton(props) {
  // Access the CartContext
  const cartContext = useContext(CartContext);

  // State variables for cart item count and bump animation
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isBumpAnimation, setBumpAnimation] = useState(false);

  // Effect hook to load cart items and total amount from localStorage on component mount
  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    const savedTotal = JSON.parse(localStorage.getItem('totalAmount'));
    if (savedCartItems && savedTotal) {
      // Set the cart items and total amount in the CartContext
      cartContext.setCartItems(savedCartItems);
      cartContext.setTotalAmount(savedTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect hook to save cart items and total amount to localStorage when they change
  useEffect(() => {
    if (cartContext.cartItems.length >= 0) {
      localStorage.setItem('cartItems', JSON.stringify(cartContext.cartItems));
      localStorage.setItem('totalAmount', JSON.stringify(cartContext.totalAmount));
    }
  }, [cartContext.cartItems, cartContext.totalAmount]);

  // Effect hook to update cart item count and trigger bump animation when cart items change
  useEffect(() => {
    if (cartContext.cartItems.length >= 0) {
      // Calculate the total quantity of cart items
      const totalQuantity = cartContext.cartItems.reduce((total, cartItem) => {
        return total + cartItem.amount;
      }, 0);

      // Update the cart item count state
      setCartItemCount(totalQuantity);

      // Trigger the bump animation
      setBumpAnimation(true);

      // Remove the bump animation class after the animation completes
      const timerId = setTimeout(() => {
        setBumpAnimation(false);
      }, 300); // The duration of the 'bump' animation in milliseconds (same as defined in CSS)

      return () => clearTimeout(timerId);
    }
  }, [cartContext.cartItems]);

  // Render the cart button with the appropriate class for the bump animation
  return (
    <Button onClick={props.onClick} className={isBumpAnimation ? 'cart-button bump' : 'cart-button'}>
      <FontAwesomeIcon className='icon' icon={faCartShopping} />
      <span>Your Cart</span>
      <div className='badge'>{cartItemCount}</div>
    </Button>
  );
}

export default CartButton;