// Import necessary dependencies and styles
import React from 'react';
import './Summary.css';

// Defines the summary card with the text written below
function Summary() {
  return (
    // Container for the summary section
    <div className='summary'>
      {/* Heading */}
      <h2>Example Food Ordering Application</h2>
      {/* Summary text */}
      <p>
        This is a dummy food ordering app that was built with React
        on the frontend and Flask on the backend. Flask is used to 
        "place your order" by automatically sending me an email that
        contains the cart contents.
      </p>

      <p> 
        Please feel free to play around with the app and all its different features. Once 
        you're done, enter your email, "place your order", and I will
        reply with the contents of your order. I highly suggest writing it
        down before you hit the order button so you can compare it with my reply.
      </p>
    </div>
  );
}

export default Summary;
