// Import necessary dependencies and styles
import React from "react";
import MealItem from "./MealItem";
import Card from "../UI/Card";
import "./Meals.css";

// Displays the meal items through data that comes 
// from the Meals array defined in App.js
function Meals(props) {
  return (
    // Card component to wrap the meals list
    <Card className="meals">
      {/* Unordered list to display the meals */}
      <ul>
        {/* Map through the meals data and render a MealItem component for each meal */}
        {props.meals.map((item) => {
          return (
            <li key={item.id}>
              {/* MealItem component with meal details as props */}
              <MealItem
                meal={item.name}
                description={item.description}
                price={item.price}
                id={item.id}
                meals={props.meals}
              />
            </li>
          );
        })}
      </ul>
    </Card>
  );
}

export default Meals;

