// Import necessary dependencies and styles
import React from "react";
import MealForm from "./MealForm";
import "./MealItem.css";

// Defines a meal item that displays the 
// name, description, and price of each meal
function MealItem(props) {
  return (
    // Container for a single meal item
    <div className="meal">
      <div>
        {/* Meal name */}
        <h3>{props.meal}</h3>
        {/* Meal description */}
        <div className="description">{props.description}</div>
        {/* Meal price */}
        <div className="price">${props.price}</div>
      </div>
      {/* MealForm component to handle adding the meal to the cart */}
      <MealForm meals={props.meals} id={props.id} />
    </div>
  );
}

export default MealItem;

