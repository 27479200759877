// Import necessary dependencies and styles
import React, { useState } from 'react';
import CartButton from '../Cart/CartButton';
import Cart from '../Cart/Cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurger } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import foodPic from '../../images/meals.jpg';
import './Header.css';


// Defines the header bar that contains the name
// of the application and the cart button to 
// access the cart
function Header(props) {
  // State variable to manage cart visibility
  const [isClicked, setIsClicked] = useState(false);

  // Event handler to toggle the cart visibility
  const clickHandler = () => {
    setIsClicked(true);
  };

  // Callback function to update cart visibility state from the Cart component
  const saveButtonClick = (isClicked) => {
    setIsClicked(isClicked);
  };

  return (
    <Fragment>
      {/* Header section */}
      <div className='header'>
        <div className='header-title'>
          {/* Application title */}
          <h1>React Meals</h1>
          {/* Burger icon */}
          <FontAwesomeIcon icon={faBurger} size="2xl" style={{ color: "#ffffff" }} />
        </div>
        {/* Cart button */}
        <CartButton onClick={clickHandler} />
      </div>

      {/* Main image section */}
      <div className='main-image'>
        <img src={foodPic} alt='Examples of meals' />
      </div>

      {/* Cart component */}
      {/* Show the Cart component only when the cart button is clicked */}
      {isClicked && <Cart meals={props.meals} onSaveButtonClick={saveButtonClick} />}
    </Fragment>
  );
}

export default Header;
