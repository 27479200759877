// Import necessary dependencies
import React from 'react';
import Header from './components/Layout/Header';
import Summary from './components/Meals/Summary';
import Meals from './components/Meals/Meals';

// Define the main App component that renders
// the three main sub-components in the app.
function App() {
  // Sample meal data for testing purposes
  const DUMMY_MEALS = [
    {
      id: 'm1',
      name: 'Sushi',
      description: 'Finest fish and veggies',
      price: "22.99"
    },
    {
      id: 'm2',
      name: 'Schnitzel',
      description: 'A German specialty!',
      price: "16.50"
    },
    {
      id: 'm3',
      name: 'Barbecue Burger',
      description: 'American, raw, meaty',
      price: "12.99"
    },
    {
      id: 'm4',
      name: 'Green Bowl',
      description: 'Healthy...and green...',
      price: "18.99"
    },
  ];

  // Render the application's structure using JSX
  return (
    <div>
      {/* Render the Header component and pass the sample meal data as a prop named 'meals' */}
      <Header meals={DUMMY_MEALS}/>
      {/* Render the Summary component */}
      <Summary />
      {/* Render the Meals component and pass the sample meal data as a prop named 'meals' */}
      <Meals meals={DUMMY_MEALS}/>
    </div>
  );
}

// Export the App component as the default export
export default App;

