import React, {useState, useContext} from "react";
import { Fragment } from "react";
import Modal from "../UI/Modal";
import CartItem from "./CartItem";
import CartContext from "../../Context/cart-context";
import Input from "../UI/Input";
import Axios from 'axios';
import "./Cart.css";

// Cart component that renders the cart items
// and a small order form to place an order.
function Cart(props) {
  // Define and initialize state variables using useState hook
  const [buttonClicks, setButtonClicks] = useState({
    isCloseClicked: false,
    isOrderClicked: false
  });

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [orderPlaced, setOrderPlaced] = useState(false);

  // Access the CartContext to retrieve the total amount
  // and eventually the cartItems array
  const cartContext = useContext(CartContext);
  const totalAmount = cartContext.totalAmount.toFixed(2);

  // Create data objects for the order that will get
  // sent to the server.
  const miscData = {
    email: email,
    totalAmount: totalAmount
  };

  const orderData = {
    misc: miscData,
    order: cartContext.cartItems
  };

  // Event handlers
  const closeClickHandler = () => {
    // Update buttonClicks state when Close button is clicked
    setButtonClicks((prevState) => {
      return { ...prevState, isCloseClicked: true };
    });
    // Call the onSaveButtonClick callback with the updated state
    props.onSaveButtonClick(buttonClicks.isCloseClicked);
  };

  const orderClickHandler = () => {
    // Update buttonClicks state when Order button is clicked
    setButtonClicks((prevState) => {
      return { ...prevState, isOrderClicked: true };
    });
  };

  const emailInputHandler = (event) => {
    // Update email state and validate email input
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsValidEmail(emailInput.includes('@'));
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (isValidEmail === true && email !== "") {
      // Send the order data to the server using Axios
      Axios.post("https://food-order-app-api.onrender.com/form-data", orderData)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });

      // Update state after the order is successfully placed
      setOrderPlaced(true);
      cartContext.clearCart();
    }
  };

  return (
    <Fragment>
      <Modal className='background'>
        <ul className='cart-items'>
          {/* Render cart items if there are any */}
          {cartContext.cartItems.length !== 0 && cartContext.cartItems.map(meal => {
            return <CartItem key={meal.id} amount={meal.amount} name={meal.name} price={meal.price.toFixed(2)} />;
          })}
        </ul>
        {cartContext.cartItems.length > 0 && (
          <div className='total'>
            {/* Show subtotal if there are items in the cart */}
            <label htmlFor="total-amount">Subtotal:</label>
            <span id="total-amount">${totalAmount}</span>
          </div>
        )}
        <div className={cartContext.cartItems.length > 0 ? "form-container" : ""}>
          {/* Show form instructions and email input if there are items in the cart */}
          {cartContext.cartItems.length > 0 && (
            <p className="form-instructions">
              Input your email and I will <br /> reply from lutzmatt17@gmail.com <br /> with your order.
            </p>
          )}
          <form onSubmit={submitHandler} className="actions">
            {/* Render email input and buttons if there are items in the cart */}
            {cartContext.cartItems.length > 0 && (
              <div className="email-input">
                <Input type="email" onChange={emailInputHandler} id='email'>Email</Input>
              </div>
            )}
            <div className="buttons">
              <button onClick={closeClickHandler}>Close</button>
              <button type="submit" onClick={orderClickHandler}>Order</button>
            </div>
          </form>
        </div>
      </Modal>
      {/* Show a confirmation modal when the order is placed */}
      {orderPlaced && (
        <Modal className='background'>
          <div className="order-placed">
            <h1>Your Order Has Been Placed!</h1>
          </div>
          <div className="buttons">
            <button onClick={closeClickHandler}>Close</button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
}

export default Cart;
