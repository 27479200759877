// Import necessary dependencies and styles
import React from 'react';
import './Card.css';

// Defines a generalized card component that
// is used throughout the application
function Card(props) {
  // Concatenate the provided className with the base 'card' class
  let cardCSS = 'card ' + props.className;

  return (
    // Render a div element with the provided className
    <div className={cardCSS}>
      {/* Render the content of the card */}
      {props.children}
    </div>
  );
}

export default Card;
