// Import necessary dependencies and styles
import React from "react";
import "./Input.css";

// Define the Input component using React.forwardRef 
// to forward the ref the input element
const Input = React.forwardRef((props, ref) => {
  return (
    // Container for the input field
    <div className="input">
      {/* Label for the input field */}
      <label htmlFor={props.id}>{props.children}</label>
      {/* Input element */}
      <input
        onChange={props.onChange} // Event handler for input change
        type={props.type} // Input type (e.g., text, number, etc.)
        inputMode={props.inputMode} // Input mode (e.g., numeric, text, etc.)
        id={props.id} // Input id
        name={props.name} // Input name
        value={props.value} // Input value
        ref={ref} // Forwarded ref to the input element
        style={props.style} // Custom styles for the input element
      />
    </div>
  );
});

export default Input;

