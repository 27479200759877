// Import necessary dependencies
import React, { useState } from "react";

// Create a context object with default values
const CartContext = React.createContext({
  cartItems: [],
  onCartItem: () => {},
  setCartItems: () => {},
  onHandleAmount: () => {},
  onHandleIncDec: () => {},
  totalAmount: 0,
  setTotalAmount: () => {},
  clearCart: () => {},
});

// Define the CartContextProvider component
export function CartContextProvider(props) {
  // State variables for cart items and total amount
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  // Function to handle adding a cart item to the cart
  const cartItemHandler = (cartItem) => {
    setCartItems((prevState) => {
      return [...prevState, cartItem];
    });
    setTotalAmount((prevAmount) => {
      return prevAmount + cartItem.price * cartItem.amount;
    });
  };

  // Function to handle updating the amount of an existing cart item
  const handleAmount = (newCartItem) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.name === newCartItem.name) {
        return { ...item, amount: item.amount + newCartItem.amount };
      } else {
        return item;
      }
    });
    findTotalAmount(updatedCartItems);
    setCartItems(() => updatedCartItems);
  };

  // Function to handle incrementing or decrementing the amount of a cart item
  const handleIncDec = (cartItemName, newAmount) => {
    const updatedCartItems = cartItems.map((item) => {
      let finalAmount = item.amount + newAmount;
      if (item.name === cartItemName) {
        return { ...item, amount: finalAmount };
      } else {
        return item;
      }
    });
    const finalCartItems = updatedCartItems.filter((item) => item.amount !== 0);
    findTotalAmount(finalCartItems);
    setCartItems(() => finalCartItems);
  };

  // Function to calculate and update the total amount based on cart items
  const findTotalAmount = (cartItems) => {
    let totalAmount = 0;
    for (let i = 0; i < cartItems.length; i++) {
      totalAmount += cartItems[i].price * cartItems[i].amount;
    }
    setTotalAmount(totalAmount);
  };

  // Function to clear the cart
  const clearCart = () => {
    let clearedCart = [];
    setCartItems(() => clearedCart);
    setTotalAmount(0);
  };

  return (
    // Provide the CartContext values to its descendants using the Provider component
    <CartContext.Provider
      value={{
        cartItems: cartItems,
        setCartItems: setCartItems,
        onCartItem: cartItemHandler,
        onHandleAmount: handleAmount,
        onHandleIncDec: handleIncDec,
        totalAmount: totalAmount,
        setTotalAmount: setTotalAmount,
        clearCart: clearCart
      }}
    >
      {/* Render the children components wrapped inside the CartContextProvider */}
      {props.children}
    </CartContext.Provider>
  );
}

// Export the CartContext and CartContextProvider
export default CartContext;

