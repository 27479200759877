// Import necessary dependencies and styles
import React, { useReducer, useRef, useContext, useEffect } from "react";
import Button from "../UI/Button";
import Input from "../UI/Input";
import CartContext from "../../Context/cart-context";
import "./MealForm.css";

// Initial state for the cart item
const initialState = {
  name: "",
  price: 0,
  amount: "",
  id: Math.random().toString(),
};

// Action types for the useReducer function
const ACTIONS = {
  HANDLE_AMOUNT: "handle-amount",
  SET_NAME_PRICE: "set-name-price",
};

// Reducer function to handle state updates based on the action type
const reducer = (cartItem, action) => {
  switch (action.type) {
    case ACTIONS.HANDLE_AMOUNT:
      return { ...cartItem, amount: action.payload.amount };
    case ACTIONS.SET_NAME_PRICE:
      console.log("Dispatch called");
      return {
        ...cartItem,
        name: action.payload.name,
        price: action.payload.price,
      };
    default:
      return cartItem;
  }
};

// Defines the meal form for each meal item that includes 
// and handles the sending of chosen meal data to the cart
function MealForm(props) {
  const inputRef = useRef();
  const cartContext = useContext(CartContext);
  const [cartItem, dispatch] = useReducer(reducer, initialState);

  // Event handler to update the cart item amount
  const amountChangeHandler = (event) => {
    dispatch({
      type: ACTIONS.HANDLE_AMOUNT,
      payload: { amount: Number(event.target.value) },
    });
  };

  // Effect hook to update the cart item name and price from the meals prop
  useEffect(() => {
    const mealToAdd = props.meals.filter((meal) => meal.id === inputRef.current.id);
    const name = mealToAdd[0].name;
    const price = mealToAdd[0].price;
    dispatch({
      type: ACTIONS.SET_NAME_PRICE,
      payload: { name: name, price: Number(price) },
    });
  }, [props.meals]);

  // Event handler to add the cart item to the cartContext
  const submitHandler = (event) => {
    event.preventDefault();
    if (
      cartContext.cartItems.find((item) => item.name === cartItem.name) &&
      cartItem.amount > 0
    ) {
      // Update the amount if the cart item already exists in the cart
      cartContext.onHandleAmount(cartItem, cartItem.amount);
    } else if (cartItem.amount > 0) {
      // Add the cart item to the cart if it doesn't exist yet
      cartContext.onCartItem(cartItem);
    }
    console.log("Adding Cart Item");
    console.log(cartContext.cartItems);

    // Reset the cart item amount to its initial value
    dispatch({
      type: ACTIONS.HANDLE_AMOUNT,
      payload: { amount: initialState.amount },
    });
  };

  return (
    <form onSubmit={submitHandler} className="form">
      {/* Input field for the cart item amount */}
      <Input
        style={{ appearance: "textfield" }}
        inputMode="numeric"
        onChange={amountChangeHandler}
        value={cartItem.amount}
        type="number"
        id={props.id}
        ref={inputRef}
      >
        Amount
      </Input>
      {/* Hidden input fields for the cart item name and price */}
      <Input type="hidden" value={cartItem.name} />
      <Input type="hidden" value={cartItem.price} />
      {/* Button to add the cart item to the cart */}
      <Button>+ Add</Button>
    </form>
  );
}

export default MealForm;

