// Import necessary dependencies and styles
import React from 'react';
import './Button.css';

// Defines a generalized button wrapper that
// is used throughout the application
function Button(props) {
  // Concatenate the provided className with the base 'button' class
  let buttonCSS = 'button ' + props.className;
  
  return (
    // Render a button element with the provided className and click event handler
    <button onClick={props.onClick} className={buttonCSS}>
      {/* Render the content of the button */}
      {props.children}
    </button>
  );
}

export default Button;
